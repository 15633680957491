body {
  background-image: url(background.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 100vh;
}

/* Basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  gap: 10px;
  padding: 10px;
}

.photo {
  position: relative;
  overflow: hidden;
  animation: fadeIn 2s ease-in infinite;
  background-color: red;
  border-radius: 1em;
  padding: 5px;
}

.photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  transition: transform 0.3s ease;
  border-radius: 1em;
}

.photo img:hover {
  transform: scale(1.1);
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0.7;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
